.field-btn {
  cursor: pointer;
  background: #f7f7f8;
  border-radius: 8px;
  border: 1px solid #eeeeee;
  padding: 0.875rem 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 48%;
}

.field-btn p {
  margin-left: 1rem;
  text-align: left;
}

.template-builder-page .active {
  background: #ffc3c54d;
  border: 1px dashed #ec1c24;
  border-radius: 8px;
}

.template-builder-page .drop-cont .drag-btn {
  display: none;
}
.template-builder-page p {
  margin: 0;
  text-align: inherit;
}
.template-builder-page .suggestions {
  padding: 1.5rem;
}
.template-builder-page .drop-cont {
  padding: 1.5rem;
  height: 80vh;
  border-right: 1px solid #e5e7eb;
  overflow-y: auto;
}
.template-builder-page .suggestion-btn {
  border-radius: 0.5rem;
  cursor: pointer;
  background-color: rgb(243 244 246);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: none;
  outline: none;
}
.template-builder-page .suggestion-btn-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-top: 1.5rem;
}
.template-builder-page .suggestion-btn-text {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin-top: 0.5rem;
  font-weight: 500;
}
.template-builder-page .drop-cont .MuiContainer-root > div {
  position: relative;
}

.template-builder-page .mt-img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  margin: auto;
  display: block;
}

.template-builder-page .action-stack {
  position: absolute;
  top: 0;
  transition: all 0.5s;
  opacity: 0;
  right: 0;
  z-index: 10;
}
.template-builder-page .action-stack button {
  background: #eee;
  padding: 0.5rem;
}
.template-builder-page .drop-cont .MuiContainer-root > div:hover .action-stack {
  opacity: 1;
}

.template-builder-page .suggestions .show-fields-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.template-builder-page .suggestions .show-fields-cont button {
  display: none;
}

.template-builder-page .form-wrapper-head {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  background-color: white;
  border-radius: 0.5rem;
}
.template-builder-page .form-wrapper-head .other-border {
  border-bottom: 1px solid #e5e7eb;
}
.template-builder-page .form-wrapper-head .form-head-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-right: 1px solid #e5e7eb; /* Assuming grey-200 */
  border-bottom: 1px solid #e5e7eb;
}
.template-builder-page .col-span-2 {
  grid-column: span 2 / span 2;
}
.template-builder-page .title {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
}

.template-builder-page .btn-cont {
  display: flex;
  gap: 1rem;
}
.btn-wrap {
  display: flex;
  gap: 1rem;
  margin-top: auto;
}
.btn-wrap button {
  flex: 1;
}
@media screen and (max-width: 1024px) {
  .template-builder-page .drop-cont .drag-btn {
    display: block;
  }

  .template-builder-page .drop-cont .drag-text {
    display: none;
  }

  .template-builder-page .form-wrapper-head {
    display: block;
    border: none;
  }
  .template-builder-page .drop-cont .action-stack {
    opacity: 1;
  }
  .template-builder-page .drop-cont .action-stack button {
    width: 35px;
    height: 35px;
    background: #eee9;
  }
  .template-builder-page .suggestions {
    position: fixed;
    top: 0;
    background: #fff;
    bottom: 0;
    right: -150%;
    width: 90%;
    max-width: 400px;
    z-index: 10000;
    transition: 0.5s right;
  }
  .template-builder-page .suggestions.open {
    right: 0;
  }
  .template-builder-page .other-border.d-none {
    display: none;
  }
  .template-builder-page .other-border {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10000;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.48);
  }
  .template-builder-page .drag-box {
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.5rem;
    width: 100%;
    border: 1px dashed #ec1c24;
    border-radius: 10px;
    background: #ec1c2412;
    margin-top: 1rem;
  }

  .template-builder-page .suggestions .show-fields-cont button {
    display: block;
  }
}

@media screen and (max-width: 640px) {
  .template-builder-page .form-wrapper-head .form-head-content {
    display: block;
  }

  .template-builder-page .form-wrapper-head .form-head-content .btn-cont {
    margin-top: 1rem;
  }
}
