.drop-down-wrapper {
	position: relative;
}
.drop-down-wrapper .drop-down-content {
	width: fit-content;
	position: absolute;
	right: 0%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 0.5rem;
	box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
	border-radius: 7px;
	background-color: white;
	transform: scale(0);
	transition: transform 0.2s ease;
	width: max-content;
	min-width: 150px;
	z-index: 10;
}
.drop-down-content.show-drop-down {
	transform: scale(1);
}
.drop-down-content .hr {
	width: 100%;
	border: 1px solid #edf2f7;
}
.drop-down-content button,
.drop-down-content a {
	width: 100%;
	justify-content: flex-start;
	margin-top: 0rem;
	font-size: 0.875rem;
	text-transform: capitalize;
	align-items: center;
}
.drop-down-content button svg,
.drop-down-content a svg {
	margin-right: 0.5rem;
}
.drop-down-content button:first-child,
.drop-down-content a:first-child {
	margin-top: 0;
}

.origin-top-right {
	transform-origin: top right;
}
.origin-top-left {
	transform-origin: top left;
}
.origin-bottom-right {
	transform-origin: bottom right;
}
.origin-top-right {
	transform-origin: top right;
}
.top-\[120\%\] {
	top: 120%;
}
.bottom-\[120\%\] {
	bottom: 120%;
}

.graph-dropdown.drop-down-wrapper .drop-down-content form {
	display: flex;
	flex-direction: column;
	width: 350px;
	max-width: 85vw;
	padding: 0.5rem;
	gap: 1rem;
	align-items: stretch;
}
.graph-dropdown.drop-down-wrapper .drop-down-content button {
	text-align: center;
	justify-content: center;
}
.graph-dropdown .drop-down-content .MuiSlider-valueLabel span {
	transform: unset;
	width: max-content;
	padding: 0 0.2rem;
	border-radius: 12px;
}
.graph-dropdown .drop-down-content .MuiSlider-valueLabel span span {
	transform: unset;
}
.graph-dropdown .drop-down-content .MuiSlider-markLabel[data-index="0"] {
	left: 0 !important;
	transform: translateX(0) !important;
}
.graph-dropdown .drop-down-content .MuiSlider-markLabel[data-index="1"] {
	right: 0 !important;
	left: unset !important;
	transform: translateX(0) !important;
}
