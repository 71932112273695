.order-template-form {
  display: flex;
  max-width: 700px;
  gap: 1rem;
  flex-wrap: wrap;
  margin: 2rem auto 4rem auto;
}
.order-template-form .input-field {
  width: 48%;
  margin-right: 0;
  margin-left: 0;
}

.dialog-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  padding: 1.5rem;
  z-index: 10;
  background-color: white;
}

@media (max-width: 560px) {
  .order-template-form .input-field {
    width: 100%;
  }
}
